import NotFoundPageModel from 'Models/Pages/NotFoundPage/NotFoundPageModel.interface';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { applyEditModeAttr } from 'Shared/Common/Helpers';
import ContentContainer from 'DesignComponents/Molecules/ContentContainer/ContentContainer';
import ContentArea from 'DesignComponents/Organisms/ContentArea/ContentArea';
import HeroModule from 'DesignComponents/Organisms/Hero/HeroModule';

function NotFoundPage() {
  const { hero, contentArea, inEditMode } = useCurrentPage<NotFoundPageModel>();

  return (
    <>
      {hero && <HeroModule hero={hero} css={{ justifyContent: 'center' }} />}

      <ContentContainer {...applyEditModeAttr(inEditMode && 'MainContent')}>
        {Array.isArray(contentArea) && <ContentArea childItems={contentArea} />}
      </ContentContainer>
    </>
  );
}

export default NotFoundPage;
